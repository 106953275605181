<template>
  <div class="contenido">
    <ImageHeader :title="$t('MANAGER.EVALUATORS_PROGRESS')" :hasButton="true"
      v-on:openModalDiploma="openModalDiploma" />
    <b-modal v-model="isModalDiplomaActive" has-modal-card trap-focus :destroy-on-hide="false" aria-role="dialog"
      aria-modal>
      <template>
        <div class="container-modal container">
          <div class="parte-superior">
            <div>
              <span>
                <font-awesome-icon class="icono-modal white" :icon="['fal', 'award']" />
                <span class="title-m bold white">Generar diploma</span>
              </span>
            </div>
            <div>
              <button class="btn-modal btn button" @click="isModalDiplomaActive = false">
                <span>
                  <font-awesome-icon class="icono" :icon="['fal', 'times']" />
                  <span class="text-l white">{{
                    $t("THANKS_YOU.CLOSE")
                  }}</span>
                </span>
              </button>
            </div>
          </div>
          <div class="stimulus text-l gray">
            <form @submit.prevent="validateBeforeSubmit">
              <div class="columns is-10">
                <div class="column">
                  <b-field>
                    <b-select placeholder="Idioma" v-model="formDiploma.idioma" v-validate="'required'" name="idioma">
                      <option value="es">Castellano</option>
                      <option value="ca">Català</option>
                      <option value="and">Català - Andorra</option>
                      <option value="eu">Euskera</option>
                      <option value="gl">Gallego</option>
                      <option value="fr">Francés</option>
                      <option value="ba">Balear</option>
                    </b-select>
                  </b-field>
                </div>
                <div class="column">
                  <b-field>
                    <b-input type="number" name="usuarioId" placeholder="Código usuario" v-validate="'required'"
                      v-model="formDiploma.usuarioId"></b-input>
                  </b-field>
                </div>
              </div>
              <div class="columns is-10">
                <div class="column">
                  <button class="btn-finish button" type="submit">
                    <span class="text-l bold white">GENERAR</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </template>
    </b-modal>
    <div class="is-10 container-data container">
      <div class="container is-10 data">
        <div class="columns personal-data">
          <div class="is-5 is-offset-1 column has-text-left">
            <p class="title-m black bold">{{ user.nombre }} {{ user.primer_apellido }} {{ user.segundo_apellido }}</p>
            <br />
            <p class="text-m bold black">{{$t('MANAGER.PHASE', {faseNum: numFase})}}</p>
          </div>
          <div class="is-5 column count-manager has-text-right">
            <span class="title-m bold black">{{$t('MANAGER.EVALUATORS', {evaluatorsNum: jurados.length})}}</span>
          </div>
        </div>
        <div class="columns selectors">
          <div class="is-4 is-offset-1 column has-text-left custom-select">
            <b-field>
              <b-select :placeholder="$t('STIMULUS.DATA.PROVINCE')" v-model="filters.provincia" @input="filter()">
                <option value="">{{ $t("STIMULUS.DATA.PROVINCE") }}: Todas</option>
                <option v-for="province in provincias" :key="province.value" :value="province.value">
                  {{ province.value }}</option>
              </b-select>
            </b-field>
          </div>
          <div class="is-4 is-offset-2 column has-text-right custom-select">
            <b-field>
              <b-select :placeholder="$t('LOGIN.LANGUAGE')" icon="globe" icon-pack="fal" v-model="filters.idioma"
                @input="filter()">
                <option v-if="idiomas.length !== 1" value="">{{ $t("LOGIN.LANGUAGE") }}: Todos</option>
                <option v-for="idioma in idiomas" :key="idioma.value" :value="idioma.value">
                  {{ $t("LOGIN.LANGUAGES." + idioma.key) }}</option>
              </b-select>
            </b-field>
          </div>
        </div>
        <!-- <div class="columns">
                <div class="is-10 column is-offset-1 has-text-left titulo-seccion">
                    <div>
                        <p class="title-m black"><b>Madrid - Castellano</b></p>
                    </div>
                    <div>
                        <hr />
                    </div>
                </div>
            </div> -->
        <div class="columns" v-if="jurados">
          <div class="column is-10 is-offset-1">
            <table class="table">
              <thead>
                <tr>
                  <th v-for="(column, index) in columns" :key="index" scope="col"
                    :class="{'has-text-centered': column.centerTitle}">
                    <b class="text-m">{{ column.title }}</b>
                    <!-- <b class="text-m">{{ $t(column.title) }}</b> -->
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(jurado, index) in jurados" :key="index">
                  <td class="has-text-centered contracte-field" style="padding-top: 2.2em;">
                    {{ jurado.usuario_id }}
                  </td>
                  <td class="email-text" style="padding-top: 2.2em;">{{ jurado.nombre }} {{ jurado.primer_apellido }}
                    {{ jurado.segundo_apellido }}</td>
                  <td class="has-text-centered bar-td">
                    <ProgressBar :noLiterals="true" :withPadding="true" :value="jurado.num_evaluados"
                      :total="jurado.num_relatos" />
                  </td>
                  <td class="has-text-centered contracte-field" style="padding-top: 2.2em;">
                    {{ jurado.finalizado ? 'Sí' : 'No' }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ImageHeader from "@/components/ImageHeader.vue";
  import ProgressBar from "@/components/ProgressBar.vue";
  import { AREAS, FASES } from "../constants.js";

  export default {
    name: "gestor",
    components: {
      ImageHeader,
      ProgressBar
    },
    data() {
      return {
        columns: [{
          title: 'Código',
          centerTitle: true
        }, {
          title: 'Nombre',
          centerTitle: false
        }, {
          title: 'Progreso evaluaciones',
          centerTitle: true
        }, {
          title: 'Ha finalizado',
          centerTitle: true
        }],
        filters: {
          provincia: "",
          idioma: ""
        },
        fase: "",
        numFase: "",
        user: {},
        provincias: [],
        idiomas: [],
        jurados: [],
        isModalDiplomaActive: false,
        formDiploma: {
          idioma: null,
          usuarioId: null
        }
      };
    },
    created() {
      this.$store.commit("setShowSpinner", true);
      this.$store.dispatch("getFase").then(fases => {
        fases = fases.filter(obj => obj.activa);
        if (fases.some(obj => obj.codigo == FASES.EVALUACION_1)) {
          this.fase = FASES.EVALUACION_1;
          this.numFase = FASES.EVALUACION_1.split('_')[1];
        }
        else if (fases.some(obj => obj.codigo == FASES.EVALUACION_2)) {
          this.fase = FASES.EVALUACION_2;
          this.numFase = FASES.EVALUACION_2.split('_')[1];
        }
        this.$store.dispatch("getUser").then((user) => {
          this.user = user;
          this.provincias = AREAS[user.area].provincias;
          this.idiomas = AREAS[user.area].idiomas;
          if (this.idiomas.length === 1) {
            this.filters.idioma = this.idiomas[0].value;
          }
          this.$store.dispatch("getEvaluacionesGestor", {
            fase: this.fase,
            area: this.user.area
          }).then(jurados => {
            const tmp = [];

            jurados.forEach(jurado => {
              if (tmp.find(j => j.usuario_id === jurado.usuario_id) === undefined) {
                jurado.finalizado = jurados.filter(j => j.usuario_id === jurado.usuario_id).map(j => j.finalizado).filter(j => j === false).length === 0;
                tmp.push(jurado);
              }
            });

            this.jurados = tmp;
          });
          this.$store.commit("setShowSpinner", false);
        }).catch(error => {
          this.$store.commit("setShowSpinner", false);
        });
      });
    },
    methods: {
      filter() {
        if (this.filters.provincia) {
          if (AREAS[this.user.area].idiomas.length !== 1) {
            this.idiomas = AREAS[this.user.area].provincias.find(p => p.value === this.filters.provincia).idiomas;
            if (this.idiomas.length === 1) {
              this.filters.idioma = this.idiomas[0].value;
            }
          }
        } else {
          this.idiomas = AREAS[this.user.area].idiomas;
        }
        this.$store.dispatch("getEvaluacionesGestor", {
          fase: this.fase,
          area: this.user.area,
          provincia: this.filters.provincia,
          idioma: this.filters.idioma
        }).then(jurados => {
          const tmp = [];

          jurados.forEach(jurado => {
            if (tmp.find(j => j.usuario_id === jurado.usuario_id) === undefined) {
              jurado.finalizado = jurados.filter(j => j.usuario_id === jurado.usuario_id).map(j => j.finalizado).filter(j => j === false).length === 0;
              tmp.push(jurado);
            }
          });

          this.jurados = tmp;
        });
      },
      openModalDiploma() {
        this.isModalDiplomaActive = true;
      },
      validateBeforeSubmit() {
        this.$validator.validateAll().then((result) => {
          this.$store.commit("setShowSpinner", true);
          this.$store
            .dispatch("downloadDiploma", {
              idioma: this.formDiploma.idioma,
              usuario_id: this.formDiploma.usuarioId,
            })
            .then((response) => {
              const file = new Blob([response], {
                type: "application/pdf",
              });
              this.$store.commit("setShowSpinner", false);
              let link = document.createElement("a");
              link.href = window.URL.createObjectURL(file);
              link.download = `diploma_${this.formDiploma.usuarioId}.pdf`;
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
              this.isModalDiplomaActive = false;
            })
            .catch((error) => {
              this.$store.commit("setShowSpinner", false);
            });
        });
      }
    }
  };
</script>

<style scoped lang="scss">
  @import '../styles/variables.scss';

  .personal-data {
    padding-top: 25px;
  }

  .selectors {
    background-color: $primary;
    margin-left: 0px;
    margin-right: 0px;
  }

  .container-data {
    margin-top: -150px;
    padding-bottom: 120px;
  }

  .container.data {
    background-color: white;
  }

  .count-manager {
    position: relative;
    top: 20px;
  }

  .titulo-seccion {
    margin-top: 10px;
    margin-bottom: 16px;

    display: flex;
    align-items: center;

    div {
      min-width: fit-content;
    }

    div:last-child {
      width: -webkit-fill-available;
      margin-left: 5%;
      height: 20px;
    }
  }

  hr {
    margin: 0.10rem 0;
  }

  .table {
    width: 100%;
    margin-bottom: 25px;
    border-collapse: separate;
    border-spacing: 0px 12px;
  }


  .table td,
  .table th {
    border: none;
    padding: 1em 1.25em;
  }

  .table tbody tr {
    background-color: $background-box;

    td:first-child {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }

    td:last-child {
      border-bottom-right-radius: 10px;
      border-top-right-radius: 10px;
    }
  }

  .profileImage {
    max-width: 50px;
    max-height: 50px;
    border-radius: 50%;
    margin-right: 20px;
  }

  .btn {
    background-color: white;
    height: 50px;
    border-radius: 30px;
    border: none;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);

    // width: 100%;
    .content-button {
      display: flex;
      align-items: center;
    }

    .icono {
      font-size: 20px;
      color: $primary;
      margin-right: 10px;
    }
  }

  .icono-modal {
    font-size: 25px;
    margin-right: 20px;
  }

  .btn-modal {
    background-color: $primary;

    .icono {
      color: white;
    }
  }

  .parte-superior {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .parte-superior.welcome {
    justify-content: flex-end;
  }

  .stimulus {
    padding: 40px;
    background-color: white;
    margin-top: 25px;
    border-radius: 10px;
  }

  .btn-finish {
    background-color: $primary;
    color: white;
    height: 60px;
    border-radius: 10px;
    margin-bottom: 30px;
    width: 100%;
  }

  .btn-finish:hover {
    color: white;
  }
</style>